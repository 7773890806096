import { Component } from "react";
import React, { useState, useEffect } from "react"
import { RightOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from "./LoginUser.module.css"
import { Button, Col, Row} from 'antd';
import { useTranslation } from "react-i18next"
import LoginForm from '../../routes/Login/LoginForm';
import ggps from '../../img/ggps.png'
type Props = {};

type State = {
    redirect: string | null,
    username: string,
    password: string,
    loading: boolean,
    message: string
};

export default class LoginUser extends Component<Props, State> {
        
    componentDidMount() {
     
      
    }

    componentWillUnmount() {
        // window.location.reload();
    }

    handleLogin(formValue: { username: string; password: string }) {
    
    }

    render() {
       
        return (
            <>
            <Row className={classes.container}>
                <Col lg={12} md={12} sm={24} xs={24}>
                <div className={classes.login}>
                    <div className={classes.title}>
                            <h3>Είσοδος με κωδικούς διαχείρισης (για διαχειριστές συστήματος)</h3>
                            <LoginForm/>
                            
                    </div>
                </div>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                <div className={classes.login}>
                    <div className={classes.title}>
                            <h3>Είσοδος με κωδικούς taxinet (για επιχειρήσεις - διακαιούχους)</h3>
                            <img src={ggps}/>
                            <button type="button" className="ant-btn css-dev-only-do-not-override-yp8pcc ant-btn-primary ant-btn-lg lbtn"><span role="img" aria-label="download" className="anticon anticon-download"></span><span><a href="/login">Σύνδεση </a></span></button>
                    </div>
                </div>
                </Col>
            </Row>
        </>
        );
    }
}