import React, { useState, useEffect } from "react"
import { Row, Col, Select, Space, Button, Dropdown } from 'antd'
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import Navbar from './Navbar'
import twitter from '../../img/twitter.png'
import etpa from '../../img/ETPA.png'
import LangSwitch from "../LangSwitch/LangSwitch";

const Header = () => {
    const handleChange = (value: string) => {
        i18n.changeLanguage(value)
    };
    const { t } = useTranslation()
    const [size, setSize] = useState<SizeType>('large');

    const items: MenuProps['items'] = [
        {
            label: 'EN',
            key: '0',
        },
        {
            label: 'ΕΛ',
            key: '1',
        },
    ];

    return (
        <>
            <div className={classes.topBarContainer}>
                <div className={classes.topBar}>

                    {/* <Dropdown
                        menu={{ items }}
                        trigger={['click']}>

                        <a onClick={(e) => e.preventDefault()}>
                            <Space className={classes.selectLang}>
                                Click me
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
 */}
                    <LangSwitch />
                </div>
            </div>
            <div className={[classes.header, classes.flexContainer].join(" ")}>

                <div className={classes.containerBottom}>

                    <div className={classes.Rectangle1}>

                        <Row justify="center">


                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <div className={classes.app_logo}>
                                            <Link to="/" state={{ page: 'home' }} ><img src="logo192.png" alt="logo" /></Link>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className={classes.etpa}  >
                                        <a href="#"><img src={etpa} alt="external-link" /></a>
                                    </Col>
                                </Row>


                            </Col>
                            <Col lg={14} md={14} sm={12} xs={12}>
                                <Row>
                                    <Col lg={16} md={16} sm={16} xs={16}>
                                        <Navbar />
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8} className={classes.loginCol}>
                                        <Link to="/loginuser" state={{ page: 'login' }} >
                                            <Button className={classes.login} type="primary" icon={<DownloadOutlined />} size={size}>
                                                {t('Είσοδος')}
                                            </Button>
                                        </Link>
                                    </Col>


                                </Row>


                            </Col>

                        </Row>

                        {/* <a href="#"><img src={twitter} className={classes.icon} alt="external-link" /></a>

                        <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a> */}

                    </div>




                </div >
            </div >
        </>
    );
};

export default Header