import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./Home.module.css"
import { Button, Col, Row, Collapse, Select, Form, Input, Space, SelectProps, Steps, notification } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined, CloseOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import requestIcon from '../../img/request.png'
import teams from '../../img/Microsoft_Office_Teams.png'
import powerPoint from '../../img/Microsoft_Office_PowerPoint.png'
import sway from '../../img/Microsoft_Office_Sway.png'
import oneNote from '../../img/Microsoft_Office_OneNote.png'
import visio from '../../img/Microsoft_Office_Visio.png'
import sharePoint from '../../img/Microsoft_Office_SharePoint.png'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AnnouncementsCarousel from "./components/AnnouncementsCarousel";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import Spinner from "../../common/Spinner";
import danger from '../../img/danger.png'

const Home = () => {
  const { t } = useTranslation()

  const [form] = Form.useForm();

  const [size, setSize] = useState<SizeType>('large'); // default is 'middle'

  const { Panel } = Collapse;
  const { Option } = Select;

  const { isOpenSearch, isOpenSearchResults, isOpenResults, isOpenFailed, toggleSearch, toggleSearchResults, toggleResults, toggleFailed } = useModal();
  const [current, setCurrent] = useState<number>(2);
  const [notification, setNotification] = useState<string>('')
  const [currentStatus, setCurrentStatus] = useState<string>('approved');

  const handleButtonClick = (step: number) => {
    switch (step) {
      case 1:
        toggleSearch();
        toggleSearchResults();
        break;
      case 2:
        toggleSearchResults();
        toggleResults();
        break;
      case 3:
        toggleResults();
        toggleFailed()
        break;
      default:
        break;
    }
  };

  const text1 = `
  Περιεχόμενο 1
`;
  const text2 = `
  Περιεχόμενο 2
`;
  const text3 = `
  Περιεχόμενο 3
`;
  const text4 = `
  Περιεχόμενο 4
`;


  type ExpandIconPosition = 'start' | 'end';

  const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };
  const onFinish = (values: any) => {
    // alert(values["firstname"]);
    console.log(values);

    toggleSearch();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const description = 'This is a description.';

  const items = [
    {
      title: 'Υποβολή',
      description: "04/02/23",
    },
    {
      title: 'Σε εξέλιξη',
      // description: "10/02/23",
    },
    {
      title: currentStatus == "rejected" ? 'Απορρίφθηκε' : currentStatus == "approved" ? 'Εγκρίθηκε' : 'Εγκρίθηκε / Απορρίφθηκε',
      description: currentStatus ? "10/02/23" : null,
      icon: currentStatus == "rejected" ? <div className={classes.closeOutlinedContainer}><CloseOutlined style={{ color: '#ED5350', width: '42px', fontSize: '21px' }} /></div> : null
    },
  ];

  const getCurrentStep = () => {
    if (!notification && current == 2 && currentStatus == "rejected") {
      console.log("status: ", currentStatus);
      setNotification('Λυπούμαστε, αλλά δεν πληρούνται τα βασικά κριτήρια συμμετοχής στο πρόγραμμα. Ευχαριστούμε που υποβάλατε την αίτησή σας.');
    }
    else if (!notification && current == 2 && currentStatus == "approved") {
      setNotification('Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας.');
      console.log("status: ", currentStatus);
      setCurrent(current + 1)
      return (current + 1);
    }
    // else if (current == 3) {
    //   setNotification('Η αίτησή σας έχει εγκριθεί. Στα στοιχεία επικοινωνίας που έχετε δηλώσει, σας έχουν σταλεί οδηγίες για τη σύνδεση στο λογαριασμό σας. Σε περίπτωση που δεν έχετε λάβει σχετικό μήνυμα, παρακαλώ επικοινωνήστε μαζί μας.');
    // }
    return current;
  }

  const useEffect = () => {

  }

  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />

      {/* step={1} = Search type modal */}
      <Modal isOpen={isOpenSearch} toggle={toggleSearch} step={1}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSearch}>x</button></div>
        <div className={classes.message}>
          <h2>Βρείτε την αίτηση σας εδώ</h2>
          <Form
            form={form}
            layout="horizontal"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className={classes.formItemContainer}>
              <Form.Item
                label="Αριθμός Αίτησης"
                name="ap_number"
                className={classes.formItem}
                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                <Input className={classes.input} />
              </Form.Item>
              <Form.Item
                label="ΑΦΜ"
                name="afm"
                className={classes.formItem}
                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                <Input className={classes.input} />
              </Form.Item>
            </div>
            <div className={classes.buttons}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="primary" className={classes.submit} htmlType="submit" onClick={() => handleButtonClick(1)}>
                  Αναζήτηση
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal isOpen={isOpenSearchResults} toggle={toggleSearchResults} step={2}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSearchResults}>x</button></div>
        <Spinner />
        <div className={classes.message}>
          <p>Παρακαλούμε περιμένετε λίγο όσο ερευνούμε την αίτησή σας.</p>
          <div className={classes.buttons}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" className={classes.submit} htmlType="submit" onClick={() => handleButtonClick(2)}>
                Αναζήτηση
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isOpenResults} toggle={toggleResults} step={3}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleResults}>x</button></div>
        <div className={classes.message} style={{ maxWidth: 628 }}>
          <h4>Στοιχεία Αίτησης</h4>

          <Row className={classes.details}>
            <Col xs={24} sm={24} lg={8} xl={8} className={classes.detailsTitle}>
              Αριθμός Αίτησης
            </Col>
            <Col xs={24} sm={24} lg={16} xl={16} className={classes.detailsContent}>
              12345678
            </Col>
            <Col xs={24} sm={24} lg={8} xl={8} className={classes.detailsTitle}>
              Επωνυμία
            </Col>
            <Col xs={24} sm={24} lg={16} xl={16} className={classes.detailsContent}>
              DOTSOFT A.E.
            </Col>
            <Col xs={24} sm={24} lg={8} xl={8} className={classes.detailsTitle}>
              ΑΦΜ
            </Col>
            <Col xs={24} sm={24} lg={16} xl={16} className={classes.detailsContent}>
              87654321
            </Col>
          </Row>

          <h4 style={{marginTop: 40}}>Κατάσταση Αίτησης</h4>
          <Steps current={getCurrentStep()} labelPlacement="vertical" items={items} />
          {notification ? <div className={classes.notificationContainer} style={{ color: `${currentStatus == "approved" ? '#33C481' : '#ED5350'}` }}>
            <p>{notification}</p>
          </div> : null}

          <div className={classes.buttons}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" className={classes.submit} htmlType="submit" onClick={() => handleButtonClick(3)}>
                Fail Modal
              </Button>
            </Form.Item>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isOpenFailed} toggle={toggleFailed} step={4}>
        <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleFailed}>x</button></div>
        <div className={classes.dangerContainer}>
          <img src={danger} alt="message" />
        </div>
        <div className={classes.message}>
          <p>Η αίτησή σας δε βρέθηκε. Παρακαλούμε ελέγξτε τα στοιχεία και δοκιμάστε ξανά ή επικοινωνήστε μαζί μας για βοήθεια.</p>
        </div>
      </Modal>

      <div className={classes.imageContainer}>
        <div className={classes.imageWelcome}>
          {/* <img src={imageWelcome} alt="Welcome Image" /> */}
          <div className={classes.imageWelcomeTitle}>
            <span>{t('Καλώς ήρθατε στoν Ψηφιακό Μετασχηματισμό Επιχειρήσεων')}</span>
          </div>
          <div className={classes.imageWelcomeDesc}>
            <span>{t('Aναβαθμίστε την επιχείρησή σας σήμερα υποβάλλοντας αίτηση για να λάβετε τις προηγμένες ψηφιακές υπηρεσίες!')}</span>
          </div>
        </div>
      </div>


      <div className={classes.container}>

        <div className={classes.benefitsContainer}>
          <div className={classes.requests}>
            <div className={classes.request}>
              <span className={classes.requestTitle}>{t('Αίτηση Συμμετοχής Ωφελούμενων')}</span>
              <span className={classes.requestDesc}>{t('Υποβάλετε την αίτηση συμμετοχής σας στο πρόγραμμα ακολουθώντας τις οδηγίες συμπλήρωσης')}</span>
              <Button className={classes.requestButton} type="primary" shape="round" size={size}>
                <img src={requestIcon} alt="Request Button" />&nbsp;{t('Αίτηση')}
              </Button>
            </div>
            <div className={classes.request}>
              <span className={classes.requestTitle}>{t('Yποβληθείσα Αίτηση')}</span>
              <span className={classes.requestDesc}>{t('Αναζήτηση υποβοληθείσας αίτησης για ανάρτηση/διόρθωση δικαιολογητικών ή έλεγχο αποτελέσματος')}</span>
              <Button className={classes.requestButton} type="primary" shape="round" size={size} onClick={toggleSearch}>
                <SearchOutlined />&nbsp;{t('Αναζήτηση')}
              </Button>
            </div>
          </div>
          <div className={classes.benefits}>
            <span className={classes.benefitsTitle}>{t('Παροχές Προγράμματος')}</span>
            <br /><br />
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: '#7BB6E1' }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>{t('Εφαρμογή chat, τηλεδιάσκεψης και τηλεκπαίδευσης')}</span><br />
                  <a href=""><span className={classes.benefitLink}>{t('Teams')}</span></a><span className={classes.benefitTitle}>{t(' - Office 365 E1 / Microsoft')}</span>
                </Col>
                <Col xs={24} sm={10} md={12} lg={10}><img className={classes.serviceIcon} src={teams} alt="Microsoft Office Teams" /></Col>
              </Row>
            </div>
            <div className={classes.benefit}>
              <Row>
                <Col xs={2} sm={2} md={1} lg={1}>
                  <div className={classes.benefitCol}>
                    <CheckOutlined style={{ color: '#7BB6E1' }} />
                  </div>
                </Col>
                <Col xs={22} sm={10} md={10} lg={13}>
                  <span className={classes.benefitTitle}>{t('Εφαρμογές παρουσιάσεων & ψηφιακού μάρκετινγκ')}</span><br />
                  <a href=""><span className={classes.benefitLink}>{t('Planer, To Do, One Drive')}</span></a><span className={classes.benefitTitle}>{t(' - Office 365 E1 / Microsoft')}</span>
                </Col>
                <Col xs={24} sm={10} md={13} lg={10}><img className={classes.serviceIcon} src={powerPoint} alt="Microsoft Office PowerPoint" /><img className={classes.serviceIcon} src={sway} alt="Microsoft Office Sway
                " /></Col>
              </Row>
            </div>
          </div>
          <br />
          <div className={classes.benefitsExtra}>
            <span className={classes.benefitsTitle} style={{ padding: '0 25px' }}>{t('Επιπλέον Παροχές')}</span>
            <br /><br />
            <div className={classes.benefitsWrapper} style={{ padding: ' 0 25px' }}>
              <div className={classes.benefit} style={{ borderTop: 'none', borderBottom: '1px solid rgba(38, 52, 137, 0.06) ' }}>
                <Row>
                  <Col xs={2} sm={2} md={1} lg={1}>
                    <div className={classes.benefitCol}>
                      <CheckOutlined style={{ color: '#7BB6E1' }} />
                    </div>
                  </Col>
                  <Col xs={22} sm={10} md={10} lg={13}>
                    <span className={classes.benefitTitle}>{t('Εφαρμογές καταγραφής σημειώσεων, διαγραμμάτων και διαδικτυακή πλατφόρμα συνεργασίας')}</span><br />
                    <a href=""><span className={classes.benefitLink}>{t('One Note, Visio, Share Point')}</span></a><span className={classes.benefitTitle}>{t(' - Office 365 E1 / Microsoft')}</span>
                  </Col>
                  <Col xs={24} sm={10} md={13} lg={10}><img className={classes.serviceIcon} src={visio} alt="Microsoft Office Visio
                " /><img className={classes.serviceIcon} src={sharePoint} alt="Microsoft Office SharePoint
                " /><img className={classes.serviceIcon} src={oneNote} alt="Microsoft Office OneNote" /></Col>
                </Row>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className={classes.announcementsContainer}>
        <div className={classes.container} style={{ background: 'transparent' }}>
          <div className={classes.announcements}>
            <span className={classes.mainTitle}>{t('Ανακοινώσεις')}</span>
            <Link to="/announcements">
              <span style={{ float: 'right' }}><u>{t('Δες τα όλα')}</u></span>
            </Link>
            <AnnouncementsCarousel />
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.faqContainer}>
          <span className={classes.benefitsTitle}>{t('Βρείτε τις απαντήσεις που χρειάζεστε')}</span>
          <br /><br />
          <Collapse
            className={classes.faq}
            defaultActiveKey={['1']}
            onChange={onChange}
            expandIconPosition={expandIconPosition}
          >
            <Panel header={t('Τι είναι Ψηφιακός Μετασχηματισμός  και πώς μπορεί να βοηθήσει την επιχείρησή μου;')} key="1">
              <div>{text1}</div>
            </Panel>
            <Panel header={t('Πως μπορώ να αποκτήσω πρρόσβαση στην συνδρομή του προγράμματος;')} key="2">
              <div>{text2}</div>
            </Panel>
            <Panel header={t('Πόσα άτομα μπορούν να χρησιμοποιήσουν την συνδρομή;')} key="3">
              <div>{text3}</div>
            </Panel>
            <Panel header={t('Θα διατηρήσω τον έλεγχο των εγγράφων μου με την συνδρομή;')} key="4">
              <div>{text4}</div>
            </Panel>
          </Collapse>
        </div>
        <br />

      </div>
    </>
  )
}

export default Home
