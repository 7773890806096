import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { Link } from "react-router-dom";
import classes from "./Announcements.module.css"
import { Button, Col, Row, Collapse, Select } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import requestIcon from '../../img/request.png'
import teams from '../../img/Microsoft_Office_Teams.png'
import powerPoint from '../../img/Microsoft_Office_PowerPoint.png'
import sway from '../../img/Microsoft_Office_Sway.png'
import oneNote from '../../img/Microsoft_Office_OneNote.png'
import visio from '../../img/Microsoft_Office_Visio.png'
import sharePoint from '../../img/Microsoft_Office_SharePoint.png'
import announcementImg from '../../img/Announcement1.png'
import { useState } from "react";

const Announcements = () => {
    const { t } = useTranslation()

    const [size, setSize] = useState<SizeType>('large'); // default is 'middle'

    const { Panel } = Collapse;
    const { Option } = Select;

    const text1 = `
  Περιεχόμενο 1
`;
    const text2 = `
  Περιεχόμενο 2
`;
    const text3 = `
  Περιεχόμενο 3
`;
    const text4 = `
  Περιεχόμενο 4
`;

    type ExpandIconPosition = 'start' | 'end';

    const [expandIconPosition, setExpandIconPosition] = useState<ExpandIconPosition>('end');

    const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
        setExpandIconPosition(newExpandIconPosition);
    };

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1056,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <>
            <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
            <div className={classes.container}>
                <div className={classes.benefitsContainer}>
                    <div className={classes.benefits}>
                        <span className={classes.benefitsTitle}>{t('Νέα / Ανακοινώσεις')}</span>
                        <br /><br />
                        <div className={classes.benefit}>
                            <Row>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                    <img src={announcementImg} alt="" />
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={16}>
                                    <span className={classes.Date}>18/02/20</span><br /><br />
                                    {/* <Link to={`../${data.id}`}> */}
                                    <Link to={`../announcements/1`}>
                                        <span className={classes.Title}>Ενημέρωση σχετικά με την τροποποίηση της Πρόσκλησης του Προγράμματος Ι </span><br /><br />
                                    </Link>
                                    <span className={classes.Desc}>Η ΕΑΤΑ, αποσκοπώντας στην αναβάθμιση της ποιότητας ζωής των κατοίκων της πόλης με την αξιοποίηση όλων των ευρωπαϊκών χρηματοδοτικών εργαλείων και εργαλείων πολιτικ...</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Announcements
