import { useState } from "react";

export default function useModal() {
  const [isOpenSearch, setisOpenSearch] = useState(false);
  const [isOpenSearchResults, setisOpenSearchResults] = useState(false);
  const [isOpenResults, setisOpenResults] = useState(false);
  const [isOpenFailed, setisOpenFailed] = useState(false);

  const toggleSearch = () => {
    setisOpenSearch(!isOpenSearch);
  };
  const toggleSearchResults = () => {
    setisOpenSearchResults(!isOpenSearchResults);
  };
  const toggleResults = () => {
    setisOpenResults(!isOpenResults);
  };
  const toggleFailed = () => {
    setisOpenFailed(!isOpenFailed);
  };

  return {
    isOpenSearch,
    toggleSearch,
    isOpenSearchResults,
    toggleSearchResults,
    isOpenResults,
    toggleResults,
    isOpenFailed,
    toggleFailed
  };
}
