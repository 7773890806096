import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form, Input, InputNumber } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Footer.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import twitter from '../../img/twitter.png'
import facebook from '../../img/facebook.png'
import espa from '../../img/ETPA.png'

const Footer = () => {

    const { t } = useTranslation()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: 'Email field is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    const onFinish = (values: any) => {
        console.log(values);
    };

    return (
        <>
            <div className={classes.footerContainer}>
                <Row justify="center">
                    <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 14 }}>
                        <Row justify="center">
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links} >
                                <div className={classes.TitleFooter}>{t('ΕΠΙΚΟΙΝΩΝΙΑ')}</div>
                                <div><span>{t('Εταιρεία Ανάπτυξης και Τουριστικής Προβολής Αθηνών')}</span></div>
                                <div><span>{t('Ξενοφώντος 7, 105 57 Αθήνα, Ελλάδα')}</span></div>
                                <div className={classes.footerAtag}>
                                    <span>
                                        <a href="tel:+302103253123">+30 210 32 53 123</a>,<br /><a href="+302105201611">+30 210 52 01 611</a>
                                    </span>
                                </div>
                                <div className={classes.footerAtag} ><span><a href="mailto:info@developathens.gr">info@developathens.gr</a></span></div>

                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
                                <div className={classes.TitleFooter}>{t('ΠΡΟΣΦΑΤΕΣ ΑΝΑΚΟΙΝΩΣΕΙΣ')}</div>
                                <div className={classes.footerAtag} ><span><u>{t('Ενημέρωση σχετικά με την τροποποίηση της Πρόσκλησης του Προγράμματος Ι ')}</u></span></div>
                                <div className={classes.footerAtag}><span><u>{t('Ψηφιακά Εργαλία ΜΜΕ')}</u></span></div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
                                <div className={classes.TitleFooter}>{t('NEWSLETTER')}</div>
                                <div>
                                    <Form
                                        {...layout}
                                        name="nest-messages"
                                        onFinish={onFinish}
                                        style={{ maxWidth: 600 }}
                                        validateMessages={validateMessages}
                                    >
                                        <Form.Item className={classes.formItem} name={['user', 'email']} label="" rules={[{ type: 'email', required: true }]}>
                                            <Input className={classes.placeholder} placeholder="Your email address" />

                                            <Button className={classes.signUp} type="primary" htmlType="submit">
                                                SIGN UP
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div><span><u>{t('Όροι Χρήσης')}</u></span></div>
                                <div><span><u>{t('Προσωπικά δεδομένα στον Διαδικτυακό τόπο')}</u></span></div>
                                <div><span><u>{t('Επικοινωνία με HelpDesk')}</u></span></div>
                            </Col>

                        </Row>
                        <div className={classes.copyrightsContainer} style={{ color: '#fff' }}>
                            © 2023 OTE / <a className={classes.dotsoft} href="https://dotsoft.gr/" target="_blank">DOTSOFT</a>. All Rights Reserved.
                        </div>
                    </Col>
                </Row >

            </div >
        </>
    );
};

export default Footer