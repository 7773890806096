import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { Link } from "react-router-dom"

const SubMenu = ({ item }: any) => {
    const [subNav, setSubNav] = useState(false)
    const showSubNav = () => setSubNav(!subNav)

    return (
        <>
            <Link to={item.path} onClick={item.subNav && showSubNav}>
                <div>
                    <span>{item.title}</span>
                </div>
                <div>
                    &nbsp; &nbsp;{item.subNav && subNav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </Link>
            {subNav && item.subNav.map((item: any, index: any) => {
                return (

                    <Link to={item.path} key={index}>
                        {item.icon}
                        <span>{item.title}</span>
                    </Link>
                )
            })}
        </>
    )
}

export default SubMenu;