import { useEffect, useState,ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./Application.module.css"
import {
  Button,
  Col,
  Row,
  Collapse,
  Select,
  Checkbox,
  Form,
  Input,
  Space,
  SelectProps,
  Radio,
  RadioChangeEvent,
  InputNumber,
  Upload,
  Alert
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import message from '../../img/message.png'

const options: SelectProps['options'] = [];
const { Option } = Select;

const Application = () => {
  const { t } = useTranslation()

  const [form] = Form.useForm();

  const [select, setSelect] = useState<string>("");

  const [value, setValue] = useState(1);

  const onFinish = (values: any) => {
    // alert(values["firstname"]);
    console.log(values);
    const params = {
      id: window.localStorage.getItem("form_token"),
      activity_id: values['activity'],
      comments: values['comment'], 
      file: values['file_list'] 
    };

    fetch(`https://map.social-network.gr/eatams/api/ApplicationForm/Create`, {
      method: 'POST',
          headers: {   
              'Content-Type' : 'multipart/form-data', 
          },
          body: JSON.stringify( params )
        })
          .then(response => {
            return response.json()
        })
      
      toggleSearch();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
    setSelect(value);
  };

  const onReset = () => {
    form.resetFields();
    setSelect("");
  };

  const searchAddress = () => {
   
  fetch(`https://map.social-network.gr/eatams/api/Auth/SearchAddress?tax_id=${tax_id}`, {
              
          method: 'GET',
              headers: {   
                  'Content-Type' : 'application/json', 
              },
          })
              .then(response => {
                  return response.json()
              })
              .then(data => {               
                 
                  form.setFieldsValue({
                    address: data.address + " " + data.address_number,
                    zipcode:data.zipcode
                  });
            })
           // form.resetFields();
  };

  const { isOpenSearch, toggleSearch } = useModal();
  const company_name = window.localStorage.getItem("company_name");
  const tax_id = window.localStorage.getItem("tax_id");
  const [file1, setFile1] = useState<File>();
  const fileList: UploadFile[] = [
  ];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile1(e.target.files[0]);
    }
  };
  useEffect(() => {
    
    localStorage.setItem('tax_id', '800050066');
    localStorage.setItem('company_name', 'ΦΟΡΕΑΣ ΠΑΡΑΒΟΛΟ 2');
   
  }, [select])

  return (
    <>
     {searchAddress()}

      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
      <div className={classes.pageContainer}>

        {/* step={0} = Contact type modal */}
        <Modal isOpen={isOpenSearch} toggle={toggleSearch} step={0}>
          <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSearch}>x</button></div>
          <div className={classes.messageContainer}>
            <img src={message} alt="message" />
          </div>
          <div className={classes.message}>
            <p>Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα σας απαντήσουμε το συντομότερο δυνατό.</p>
          </div>

        </Modal>
        <div className={classes.container}>
          <div className={classes.fcontainer}>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              layout="horizontal"
              // style={{ maxWidth: 714 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={classes.formItemContainer}>
                <Form.Item label=" "
                  name=" "
                  className={classes.formItem}
                >
                  <h2>Αίτηση Συμμετοχής Ωφελούμενων</h2>
                  <span className={classes.subName}>* Τα πεδία/ δικαιολογητικά με αυτή την ένδειξη είναι υποχρεωτικά.</span>
                </Form.Item>

                <Form.Item
                  label="Επωνυμία επιχείρησης *"
                  name="name"
                  className={classes.formItem}
                  initialValue = {company_name}
                  rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                  
                  <Input className={classes.input} disabled={true}/>
                </Form.Item>

                <Form.Item label="ΑΦΜ *"
                  name="afm"
                  className={classes.formItem}
                  initialValue = {tax_id}
                  rules={[ {
                    required: true,
                    message: 'Το πεδίο είναι υποχρεωτικό'
                  },
                  ]}>
                  <InputNumber className={classes.input} disabled={true}/>
                </Form.Item>

                <Form.Item label="Δραστηριότητα Επιχείρησης *"
                  name="activity"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                  <Radio.Group onChange={onChangeRadio} value={value} style={{ width: '100%' }}>
                    <Space direction="vertical" className={classes.RadioContainer}>
                      <Radio value={1}>Τεχνολογίες Πληροφορικής & Επικοινωνίας (ΤΠΕ)</Radio>
                      <Radio value={2}>Εφοδιαστική Αλυσίδα / Logistics</Radio>
                      <Radio value={3}>Οικονομία - Διοίκηση</Radio>
                      <Radio value={4}>Πωλήσεις - Marketing</Radio>
                      <Radio value={5}>Τεχνικά Επαγγέλματα</Radio>
                      <Radio value={6}>Τρόφιμα - Αγροδιατροφή</Radio>
                      <Radio value={7}>Τουρισμός</Radio>
                      <Radio value={8}>Περιβάλλον - Ανακύκλωση - Ενέργεια</Radio>
                      <Radio value={9}>Υγεία</Radio>
                      <Radio value={10}>Άλλο</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Διεύθυνση Επιχείρησης *"
                  name="address"
                  className={classes.formItem}
                 // initialValue = {`${address} ${address_number}`}
                  rules={[{ required: true, message: 'Η διεύθυνσή σας είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="ΤΚ "
                  name="zipcode"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Ο ΤΚ είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Πόλη*"
                  name="city"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Η πόλη είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Περιφερειακή Ενότητα (Νομος) *"
                  name="region"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Η Περιφερειακή Ενότητα είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="ΔΟΥ *"
                  name="doy"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Η ΔΟΥ είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Σταθερό Τηλέφωνο *"
                  name="home_phone_number"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το Σταθερό Τηλέφωνο είναι υποχρεωτικό πεδίο' }]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Κινητό Τηλέφωνο"
                  name="mobile_phone_number"
                  className={classes.formItem}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Email*"
                  name="email"
                  className={classes.formItem}
                  rules={[{
                    type: 'email',
                    message: 'Δεν έχει συμπληρωθεί σωστά. Ξαναπροσπαθήστε',
                  }, {
                    required: true,
                    message: 'Το email είναι υποχρεωτικό πεδίο'
                  },
                  ]}>
                  <Input className={classes.input} />
                </Form.Item>

                <Form.Item
                  label="Σχόλιο"
                  name="comment"
                  className={classes.formItem}>
                  <Input.TextArea className={classes.inputTextArea} style={{ height: 120, resize: 'none' }} maxLength={1200} />
                </Form.Item>

                <Form.Item
                  label=" "
                  name=" "
                  className={classes.formItem}>
                  <h3>Παρακαλούμε προσθέσετε τα ακόλουθα δικαιολογητικά για να ολοκληρώσετε την αίτησή σας:</h3>
                </Form.Item>
            
                <Form.Item 
                  label=""
                  name="file_list"
                  className={classes.formItem2}>
                   <Upload
                     multiple
                     listType="text"
                     className={classes.formItem2}
                     >
                    <Button  className={classes.formItem2} icon={<UploadOutlined />}>Ανέβασμα όλων των αρχείων</Button>
                  </Upload>
                </Form.Item>
              </div>
                  <br/>
              <div className={classes.formItemContainer}>
                <Form.Item name="remember" style={{ marginBottom: 20 }} wrapperCol={{ span: 24 }}>
                  <Checkbox className={classes.checkbox}>Δηλώνω ότι συγκατατίθεμαι για τη συλλογή, τη χρήση και την επεξεργασία των παραπάνω στοιχείων και προσωπικών δεδομένων, σύμφωνα με τους όρους και τις προϋποθέσεις που θέτει η Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.  </Checkbox>
                </Form.Item>

                <Form.Item name="remember" style={{ marginBottom: 70 }} wrapperCol={{ span: 24 }}>
                  <Checkbox className={classes.checkbox}>Επίσης, δηλώνω ότι συγκατατίθεμαι για την υποβολή της ανωτέρω Αίτησης στην ειδική ιστοσελίδα εκάστου δικαιούχου φορέα προκειμένου να συμμετάσχω στο πρόγραμμα.</Checkbox>
                </Form.Item>
              </div>

              <div className={classes.formItemContainer1}>
              
              <Alert message="Το εν λόγω πρόγραμμα εντάσεται στα πλαίσια του κανόνα De Minimis και χρηματοδοτείται μέσω του Πληροφοριακού Συστήματος Σώρευσης Κρατικών Ενισχύσεων Ήσσονος Σημασίας (Sorefsis.gr), με ποσό ενίσχυσης έως ΧΧΧ,00 €. Το ακριβώς ποσό θα υπολογιστεί και θα σας επικοινωνηθεί μετά την υποβολή της αίτησης." type="info" showIcon />
              </div>

              <div className={classes.buttons}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Space>
                    <Row>
                      <Col>
                        <Button htmlType="button" onClick={onReset} className={classes.reset} >Καθαρισμός</Button>
                      </Col>
                      <Col>
                        <Button type="primary" className={classes.submit} htmlType="submit">
                          Υποβολή Αίτησης
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>
              </div>
            </Form >
          </div >
        </div >
      </div >
    </>
  )
}

export default Application
