import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        
        color: '#FFF',
        fontSize: '35px',
        fontWeight: 'bold',
        lineHeight: '60px',
        marginRight: '80px',
        paddingLeft: '',
        left: "100%",
        top: "40%",
        width: "60px",
        height: "60px",
        borderRadius: '100px',
        background: '#263489',
        boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.2)'
      }}
      onClick={onClick}
    >
      <span style={{ marginLeft: '8px' }}><RightOutlined /></span>
    </div>
  )
}



export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        zIndex: 99999,
        color: '#FFF',
        fontSize: '35px',
        fontWeight: 'bold',
        lineHeight: '60px',
        marginRight: '80px',
        paddingLeft: '',
        top: "40%",
        left: "-5%",
        width: "60px",
        height: "60px",
        borderRadius: '100px',
        background: '#263489',
      }}
      onClick={onClick}
    >
      <span style={{ marginLeft: '8px' }}><LeftOutlined /></span>
    </div>
  )
}