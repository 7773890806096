import { useState } from "react"
import { Select } from 'antd'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import classes from "./LangSwitch.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";

const Header = () => {
    const handleChange = (value: string) => {
        i18n.changeLanguage(value)
    };
    const { t } = useTranslation()
    const [size, setSize] = useState<SizeType>('large');

    return (
        <Select
            style={{ color: 'white' }}
            className={classes.selectLang}
            defaultValue="gr"
            size='large'
            onSelect={handleChange}
            options={[
                {
                    key: 1,
                    value: 'en',
                    label: 'EN'
                },
                {
                    key: 2,
                    value: 'gr',
                    label: 'ΕΛ'
                }
            ]}

        ></Select>
    );
};

export default Header