import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { Link } from "react-router-dom";
import classes from "./Dashboard.module.css"
import { Button, Col, Row, Collapse, Select,Checkbox, Form, Input } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import announcementImg from '../../img/Announcement1.png'
import { useState,useEffect } from "react";
import LoginUser from '../LoginUser/LoginUser';

function Dashboard() {
    const { t } = useTranslation()
    const [applications, setApplications] = useState([]);
    const token = window.localStorage.getItem('token');
    useEffect(() => {
           //step 2: get all applications
           fetch(`https://eata-admin.dotsoft.gr/eatams/api/ApplicationForm/GetAll`, {
            method: 'GET',
                headers: {   
                  'Content-Type' : 'application/json', 
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    console.log(data);
                    setApplications(data)
                })
    },[]);

    const renderListOfApplications = () => {
      return applications.map(appl => <li>ΑΦΜ: {appl['tax_id']} - Επωνυμία: {appl['company_name']}</li>)
    }

    const onFinish = (values:any) => {
        alert(values["password"])
      };
      const onFinishFailed = (errorInfo:any) => {
        console.log('Failed:', errorInfo);
      };
    
    return (
        <>
            <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
                       
            {token!=null?
            <div className={classes.container}>
              <h2>Αιτήσεις - {token}</h2>
              <div className={classes.fcontainer}>
              {renderListOfApplications()}
                    
            </div>
            </div>
            : (window as Window).location ='./loginuser'
            }
        </>
    )
}

export default Dashboard
