import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { Link ,Navigate} from "react-router-dom";
import classes from "./Login.module.css"
import { Button, Col, Row, Collapse, Select,Checkbox, Form, Input } from 'antd';
import Dashboard from '../../routes/Dashboard/Dashboard';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import announcementImg from '../../img/Announcement1.png'
import { useState } from "react";

const LoginForm = () => {
    const { t } = useTranslation()

    const onFinish = (values:any) => {
        console.log('Values:', values);
                    //if code is already returned check if there is a valid token
                    const params = {
                      username: values['username'],
                      password: values['password'] 
                  };
            //Login as backen administrator
            fetch(`https://eata-admin.dotsoft.gr/eatams/api/Auth/Login`, {
            method: 'POST',
                headers: {   
                  'accept': '*/*',
                  'Content-Type' : 'application/json', 
                },
                body: JSON.stringify( params )
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    if(data.access_token!=null){
                        window.localStorage.setItem("token", data.access_token);
                        (window as Window).location ='./dashboard'
                    }
                })
      };
      const onFinishFailed = (errorInfo:any) => {
        console.log('Failed:', errorInfo);
      };
    
    return (
        <>
            <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
            <div className={classes.container}>
                <div className={classes.formContainer}>
                    <div className={classes.formContainer1}>
                <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
   
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Όνομα Χρήστη"
      name="username"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Κωδικός Πρόσβασης"
      name="password"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

      <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit" className={classes.btn}>
        Είσοδος
      </Button>
    </Form.Item>
  </Form>
  </div>
                </div>

            </div>
        </>
    )
}

export default LoginForm
