import axios from "axios";
import { Navigate } from "react-router-dom";
import { useState } from 'react'
import moment from "moment";
import { use } from "i18next";
import { log } from "console";

// const API_URL = "http://localhost:8080/api/auth/";
const url = new URL(window.location.href);
let urlPrev = {};
let refresh_token: any;
// const [access_token, setAccessToken] = useState<string> ()

export interface Action {
    title: string
}

class AuthService {

    getParameters() {
        let paramString = window.location.href.split('?')[1];
        let params_arr = paramString.split('&');
        for (let i = 0; i < params_arr.length; i++) {
            let pair = params_arr[i].split('=');
            return pair[1];
        }
    }
    getApplicationForm(){
        console.log('here');
        fetch(`https://eata-admin.dotsoft.gr/eatams/api/Auth/GetTokenByTaxId/117041076`, {
                
            method: 'GET',
                headers: {   
                    'Content-Type' : 'application/json', 
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    console.log(data);
                    console.log("Form Token: " + data.access_token);
                    window.localStorage.setItem("form_token", data.access_token);
                    window.localStorage.setItem("tax_id", '117041076');
                    window.localStorage.setItem("company_name", 'INVESTLINK CORPORATION S');
                    if (data.access_token) {
                        console.log('Has already a form');
                        //(window as Window).location = `/profile`
                        (window as Window).location = `/applicationform`
                    }
                    else{
                        console.log('New Form');
                        (window as Window).location = `/applicationform`
                    }
                })
    }

    async login(url1: any) {
        console.log("LOGIN");

        // if (url.searchParams.has('login')) {
        // } else {
        //     urlPrev = url1;
        //     console.log(urlPrev);
        // }
        let hint;
        //check if user is already authorized
        //this.getApplicationForm();

         if (url.searchParams.has('code')) {
            let code1 = this.getParameters();
            console.log("Authorize code: " +code1);

           // this.getApplicationForm();
            //if code is already returned check if there is a valid token
          
            //step 2: call token service to get a token
            axios.post(`https://test.gsis.gr/oauth2server/oauth/token?grant_type=authorization_code&code=${code1}&client_id=TPQIDO19419&client_secret=cB4nC52%P&scope=read&redirect_uri=https://eata-front.dotsoft.gr/login`, {
           // axios.get(`https://test.gsis.gr/oauth2server/oauth/token`, {
                headers: {   
                    'Content-Type' : 'application/json',
                    'withCredentials': true, 
                    'mode': 'no-cors'                 
                },
            })
                .then(response => {
                    return response.data
                })
                .then(data => {
                    console.log(data);
                  //  console.log("Access Token: " + data.access_token);
                  //  window.localStorage.setItem("access_token", data.access_token);

                  /*  if (data.access_token) {
                        //if there is a valid token the user is already logged in!
                        console.log("User logged in!");
                        console.log("Access Token: " + data.access_token);
                        
                        //step 3: call userinfo service to get user details
                        
                        fetch(`https://test.gsis.gr/oauth2server/userinfo?format=xml`, {
                        method: "GET",                  
                        headers: {
                            'Authorization': `Bearer: ${data.access_token}`,
                         },
                        })
                            .then(response => {
                                return response.json()
                            })
                            .then(async userdata => {
                                console.log("USER DATA!!");
                                console.log(userdata);
                                //USER IS SUCCESSFULLY LOGGEN IN WITH TAXISNET CRENTENTIALS
                                //search tax_id: 
                                //if it has already made an application-> go to Profile to see-edit it.
                                //Login as backen administrator
                                fetch(`https://eata-admin.dotsoft.gr/eatams/api/Auth/GetTokenByTaxId/`+userdata.taxid, {
                                    method: 'GET',
                                    headers: {   
                                    'Content-Type' : 'application/json', 
                                   }
                                })
                                .then(response => {
                                     return response.json()
                                })
                            })
                    }*/
                })
        }
        else{
            //step 1: call authorize service to get code
            (window as Window).location = `https://test.gsis.gr/oauth2server/oauth/authorize?client_id=TPQIDO19419&client_secret=cB4nC52%P&response_type=code&scope=read&redirect_url=https://eata-front.dotsoft.gr/login`
        }
    }

    logout = async () => {


        // fetch(`${process.env.REACT_APP_SERVER}/user/logout`, {
        //     method: 'GET',
        //     headers: {
        //         "API-KEY": `${process.env.REACT_APP_API_KEY}`,
        //         // "Content-Type": "application/json"
        //     }
        // })
        //     .then(response => {
        //         return response.text()
        //     })
        //     .then(data => {
        //         console.log(data);
        //         // this.getCurrentUser()


        //         window.localStorage.clear();
        //     })
        //     .then(data => {

        //         (window as Window).location = `${process.env.REACT_APP_URL}/`
        //     })
        //     .then(() => {
        //         window.localStorage.clear();
        //     })


        // window.localStorage.clear();
        (window as Window).location = `${process.env.REACT_APP_SERVER}/user/logout`
        console.log("LOGOUT");
    }

    getCurrentUser() {

        console.log("GET USER");

        const userStr = window.localStorage.getItem("user");

        if (userStr)
            return JSON.parse(userStr);
        else {
            // <Navigate to={"/login"} />
            return null;
        }

    }
    navigateProfile() {
        console.log("NAVIGATE PROFILE");
        (window as Window).location = `${process.env.REACT_APP_URL}/volunteer-area`
    }

    async getUserProfile() {
        console.log("GET USER PROFILE");

        if (!window.localStorage.getItem("userData") && window.location.pathname == "/volunteer-area") {
            this.login(window.location.href)
        }
        console.log(window.localStorage.getItem("expDate"));

        if (moment().isAfter(window.localStorage.getItem("expDate"))) {
            console.log("to Refresh");
            this.refreshToken(window.location.href)
        } else {
            console.log("___");

        }
        // console.log(window.localStorage.getItem("access_token"));
        // this.refreshToken(window.location.href)

        await fetch(`${process.env.REACT_APP_SERVER}/api/el/users/profile`, {
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Origin': '',
                "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data);

                window.localStorage.setItem("userData", JSON.stringify(data)!);
                // console.log(window.localStorage.getItem("userData"));
            })
            .catch((error) => {
                console.log(error)
                // this.refreshToken(window.location.href)
            });
        // console.log(window.localStorage.getItem("userData"));
    }

    ToggleParticipation = async (id: any) => {
        let actionId = id;
        console.log("TOGGLE PARTICIPATION - id: ", id);
        if ((typeof id) == "number") {
            actionId = id;

        } else if ((typeof id) == "object") {
            actionId = id.props;
        } else if ((typeof id) == "string") {
            actionId = id;
        }

        window.localStorage.getItem("access_token")

        await fetch(`${process.env.REACT_APP_SERVER}/api/volunteering-actions/${actionId}/toggle-participation`, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Origin': '',
                "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',

            }),
            body: JSON.stringify({
                'grant_type': 'client_credentials'
            })
        })
            .then(response => {
                return response.text()
            })
            .then(data => {
                console.log(data)
                // if ((typeof data) != "string") {
                //     this.refreshToken(window.location.href)
                //     this.ToggleParticipation(id)
                // }
            })
    }
    getActionNamebyId(id: number) {
        let filteredResult: Action = {
            title: '',
        };
        let myActions: any = []
        fetch(`${process.env.REACT_APP_SERVER}/api/el/volunteering-actions`, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": `${process.env.REACT_APP_API_KEY}` }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                myActions = data.volunteering_actions;
                // console.log(myActions);
                filteredResult = myActions.find((e: any) => e.id == id);
                // newArr = data.volunteering_actions.map((item:any, i:any) => (data.volunteering_actions.splice(1,i)) );
                console.log(filteredResult);
                // actions.map((action: any) => {
                //     if (action.id == id) {
                //         ActionTitle = action.title;
                //         console.log(ActionTitle);

                //     }
                // })
            });

        console.log(filteredResult.title);

        return filteredResult.title
    }

    isSigned(id: number) {
        if (window.localStorage.getItem("userData")) {
            const userData: any = window.localStorage.getItem("userData");

            if (userData) { }
            let obj = JSON.parse(userData)!;
            const actions = obj.volunteering_actions;

            const customActions = actions.map((row: any) => {
                const start = this.formatTimeQuick(row.start_date);
                row.startDate = start;
                const end = this.formatTimeQuick(row.end_date);
                row.endDate = end;
                const period = row.startDate + " - " + row.endDate;
                row.period = period;
                return row
            })
            customActions.sort((a: any, b: any) => (this.checkNewest(a.endDate, b.endDate)) ? 1 : -1)
            // data = customActions;

            let item1 = customActions.find((i: any) => i.id == id);

            if (item1) {
                return true
            }
            else
                return false
        }
        // const userData: any = window.localStorage.getItem("userData");
        // console.log(userData);
        // if (userData) { }
        // let obj = JSON.parse(userData)!;
        // const actions = obj.volunteering_actions;
        // console.log(actions)
        // let item1 = actions.find((i: any) => i.id == id);

        // if (item1) {
        //     return true
        // }
        else {
            return false
        }
    }
    getActions() {
        console.log("GET ACTIONS");
        // this.getUserProfile()
        // let customActions: any = [];
        this.getUserProfile()
        const userData: any = window.localStorage.getItem("userData");

        let obj = JSON.parse(userData!);

        const actions = obj.volunteering_actions;

        const customActions = actions.map((row: any) => {
            const start = this.formatTimeQuick(row.start_date);
            row.startDate = start;
            const end = this.formatTimeQuick(row.end_date);
            row.endDate = end;
            const period = row.startDate + " - " + row.endDate;
            row.period = period;
            const url = `/actions/action/` + row.id
            row.url = <a href={url}></a>
            return row
        })
        return customActions!
    }

    getToken() {
        console.log("GET TOKEN");
        return window.localStorage.getItem("a");
    }

    refreshToken(url: string) {
        console.log("REFRESH TOKEN");

        fetch(`${process.env.REACT_APP_SERVER}/oauth/token`, {
            method: 'POST',
            headers: {
                "API-KEY": `${process.env.REACT_APP_API_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: "refresh_token",
                refresh_token: `${window.localStorage.getItem("refresh_token")}`,
                client_id: "0uUDCoWj33hlcHTCQIEl6mgs3tt86nFD",
                client_secret: "lvqN1q9ovsMvTnEQ0QZoGXdzWbpG38fO",
            })
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            if (data.refresh_token != undefined) {
                window.localStorage.setItem("refresh_token", data.refresh_token)
                window.localStorage.setItem("access_token", data.access_token)
                this.updateExpiry()
            }

        })
            .catch((error) => {
                console.log(error)

                // this.login(window.location.href);
            });

        // console.log("Access Token: " + window.localStorage.getItem('access_token'))
        // console.log("Refresh Token: " + window.localStorage.getItem('refresh_token'))
    }

    updateExpiry() {

        var returned_endate: any = moment().add(295, 'seconds');  // see the cloning?
        console.log(returned_endate);

        window.localStorage.setItem("expDate", returned_endate)
        console.log(window.localStorage.getItem("expDate"));
        if (moment().isAfter(returned_endate)) {
            console.log("to Refresh");
        } else {
            console.log("___");

        }
        // returned_endate.isSame(expected_enddate)  // true
    }

    formatTime = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('L');
        return dateFormatted;
    }
    formatTimeQuick = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('L');
        return dateFormatted;
    }
    checkNewest(prop1: any, prop2: any) {
        let date1 = moment(prop1, moment.defaultFormat)
        let date2 = moment(prop2, moment.defaultFormat)
        if (moment(date1).isAfter(date2)) {
            // console.log(`${prop1} is the newest`);
            return false;
        }
        else {
            // console.log(`${prop2} is the newest`);
            return true;
        }
    }

}

export default new AuthService();