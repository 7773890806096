import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import i18n from "./translation";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './routes/Home/Home';
import Announcements from './routes/Announcements/Announcements';
import Login from './routes/Login/Login';
import Dashboard from './routes/Dashboard/Dashboard';
import LoginUser from './routes/LoginUser/LoginUser';
import Profile from './routes/Profile/Profile';
import User from './routes/User/User';
import ApplicationForm from './routes/ApplicationForm/ApplicationForm';
import SingleAnnouncement from './routes/SingleAnnouncement/SingleAnnouncement';
import Application from './routes/Application/Application';
import { Styles } from './styles/styles';
import Contact from './routes/Contact/Contact';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
    <Styles />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/announcements/:id" element={<SingleAnnouncement />} />
        <Route path="/loginuser" element={<LoginUser />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user" element={<User />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/applicationform" element={<ApplicationForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/form" element={<Application />} />
      </Routes>
      <Footer />
    </I18nextProvider>
  </BrowserRouter >
  
);
