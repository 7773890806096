import React from "react"
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"



const Spinner: React.FC = () => {

    const { t } = useTranslation()
    const antIcon = <LoadingOutlined style={{ fontSize:144 }} spin />;

    return (
        <Row justify="center" >
            <Col span={12} offset={0}>
                <div className={classes.loading}> <Spin className={classes.spin} size="large" indicator={antIcon} /></div>
            </Col>
        </Row>
    )
}

export default Spinner