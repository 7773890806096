import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import classes from "./Contact.module.css"
import {
  Button,
  Col,
  Row,
  Collapse,
  Select,
  Checkbox,
  Form,
  Input,
  Space,
  SelectProps
} from 'antd';

import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import message from '../../img/message.png'

const options: SelectProps['options'] = [];
const { Option } = Select;


const Contact = () => {
  const { t } = useTranslation()

  const [form] = Form.useForm();

  const [select, setSelect] = useState<string>("");

  const onFinish = (values: any) => {
    // alert(values["firstname"]);
    console.log(values);

    toggleSearch();
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
  };

  const handleChange = (value: any) => {
    console.log(`selected ${value}`);
    setSelect(value);
  };

  const onReset = () => {
    form.resetFields();
    setSelect("");

  };

  const { isOpenSearch, toggleSearch } = useModal();

  useEffect(() => {

  }, [select])

  return (
    <>
      <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
      <div className={classes.pageContainer}>
        
        {/* step={0} = Contact type modal */}
        <Modal isOpen={isOpenSearch} toggle={toggleSearch} step={0}>
          <div className={classes.overlay_header}><button className={classes.cross} onClick={toggleSearch}>x</button></div>
          <div className={classes.messageContainer}>
            <img src={message} alt="message" />
          </div>
          <div className={classes.message}>
            <p>Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα σας απαντήσουμε το συντομότερο δυνατό.</p>
          </div>

        </Modal>
        <div className={classes.container}>
          <div className={classes.fcontainer}>
            <h2>Επικοινωνήστε μαζί μας</h2>
            <span className={classes.subName}>* Τα πεδία με αυτή την ένδειξη είναι υποχρεωτικά.</span>
            <Form
              form={form}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout="horizontal"
              style={{ maxWidth: 714 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={classes.formItemContainer}>
                <Form.Item
                  label="Όνομα*"
                  name="firstname"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item label="Επώνυμο*"
                  name="lastname"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item
                  label="Email*"
                  name="email"
                  className={classes.formItem}
                  rules={[{
                    type: 'email',
                    message: 'Δεν έχει συμπληρωθεί σωστά. Ξαναπροσπαθήστε',
                  }, {
                    required: true,
                    message: 'Το πεδίο είναι υποχρεωτικό'
                  },
                  ]}>
                  <Input className={classes.input} />
                </Form.Item>
                <Form.Item
                  label="Τηλέφωνο*"
                  name="phone"
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το επώνυμό σας είναι υποχρεωτικό' }]}>
                  <Input className={classes.input} />
                </Form.Item>
              </div>

              <h2>Περιγράψτε μας το πρόβλημά σας</h2>
              <span className={classes.subName}>Πείτε μας πώς μπορούμε να σας βοηθήσουμε*</span>
              <div className={classes.formItemContainer}>
                <Form.Item
                  name={[' ', ' ']}
                  className={classes.formItem}
                  rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό' }]}>
                  <Input.TextArea className={classes.inputTextArea} style={{ height: 248, resize: 'none' }} maxLength={1000} showCount />
                </Form.Item>
              </div>
              <span className={classes.subName}>Έχετε 1000 χαρακτήρες στην διάθεσή σας</span>

              <h2>Τα στοιχεία επικοινωνίας σας</h2>
              <span className={classes.subName}>Πώς να επικοινωνήσουμε μαζί σας;</span>
              <div className={classes.formItemContainer}>
                <Form.Item>
                  <Select
                    allowClear
                    defaultValue={select}
                    onChange={handleChange}
                    className={classes.select}
                    style={{ marginTop: 16, marginBottom: 50, }}
                    options={[
                      {
                        label: 'Τηλέφωνο',
                        value: 'phone'
                      },
                      {
                        label: 'Email',
                        value: 'email'
                      }
                    ]}
                  />
                </Form.Item>

              </div>
              <div className={classes.formItemContainer}>
                <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 70 }} wrapperCol={{ span: 24 }}>
                  <Checkbox className={classes.checkbox}>Δηλώνω ότι συγκατατίθεμαι για τη συλλογή, τη χρήση και την επεξεργασία των παραπάνω στοιχείων και προσωπικών δεδομένων, σύμφωνα με τους όρους και τις προϋποθέσεις που θέτει η Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.  </Checkbox>
                </Form.Item>
              </div>

              <div className={classes.buttons}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Space>
                    <Row>
                      <Col>
                        <Button htmlType="button" onClick={onReset} className={classes.reset} >Καθαρισμός</Button>
                      </Col>
                      <Col>
                        <Button type="primary" className={classes.submit} htmlType="submit">
                          Αποστολή
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </Form.Item>

              </div>
            </Form>
          </div>
        </div >
      </div >
    </>
  )
}

export default Contact
