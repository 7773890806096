import React, { ReactNode } from "react";
import classes from './Modal.module.css'

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  step: number
}

export default function Modal(props: ModalType) {
  const modalMaxWidth = props.step === 3 ? 818 : null;
  return (
    <>
      {props.isOpen && (
        <div className={classes.modal_overlay} onClick={props.toggle}>
          <div
            onClick={(e) => e.stopPropagation()}
            className={classes.modal_box}
            style={{ maxWidth: `${modalMaxWidth}px` }}>
            {props.children}
          </div>
        </div >
      )
      }
    </>
  );
}
