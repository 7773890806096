import { useTranslation } from "react-i18next";
import HelmetComponent from '../../components/Helmet/Helmet'
import { Link } from "react-router-dom";
import classes from "./User.module.css"
import { Button, Col, Row, Collapse, Select,Checkbox, Form, Input } from 'antd';
import { SampleNextArrow, SamplePrevArrow } from '../../common/ArrowsForCarousel'
import { SearchOutlined, CheckOutlined, SettingOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import announcementImg from '../../img/Announcement1.png'
import { useState } from "react";

const User = (data:any) => {
    const { t } = useTranslation()

    const onFinish = (values:any) => {
        alert(values["password"])
      };
      const onFinishFailed = (errorInfo:any) => {
        console.log('Failed:', errorInfo);
      };
    
    return (
        <>
            <HelmetComponent title={t('ΕΑΤΑ') || undefined} descrition={t('ΕΑΤΑ') || undefined} />
            <div className={classes.container}>
              <h2>Χρήστης</h2>
              <div className={classes.fcontainer}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={8} xl={6}>
<span className={classes.clabel}>Επωνυμία</span>
<br/>
<span className={classes.clabel}>ΑΦΜ</span>
<br/>
<span className={classes.clabel}>Δραστηριότητα</span>
<br/>
<span className={classes.clabel}>Διεύθυνση</span>
<br/>
<span className={classes.clabel}>ΔΟΥ</span>
<br/>
<span className={classes.clabel}>Τηλέφωνο Επικοινωνίας</span>
<br/>
<span className={classes.clabel}>E-mail</span>
<br/>
<span className={classes.clabel}>Microsoft ID</span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={12}>
                <span className={classes.cres}>Dotsoft</span>
                  <br/>
                  <span className={classes.cres}>12334</span>
                  <br/>
                  <span className={classes.cres}>Ναυάρχου Κουντουριώτου 3, Θεσσαλονίκη 546 25</span>
                  <br/>
                  <span className={classes.cres}>Τεχνολογίες Πληροφορικής & Επικοινωνίας (ΤΠΕ)</span>
                  <br/>
                  <span className={classes.cres}>Α' Αθήνας</span>
                  <br/>
                  <span className={classes.cres}>+302310500181</span>
                  <br/>
                  <span className={classes.cres}>example@example.gr</span>
                  <br/>
                  <span className={classes.cres}>name@outlook.com</span>
                </Col>
                <Col xs={6} sm={6} md={6} lg={8} xl={6}>
                <span className={classes.clabel}>Κατάσταση</span>
                <span className={classes.cres}>Ενεργή</span>
                <span className={classes.clabel}>Διάρκεια</span>
                <span className={classes.cres}>22/02/22-23/02/23</span>
                </Col>
              </Row>
              <Row>
                <div className={classes.btnlink}>Διακοπή Συνδρομής</div>
              </Row>
            </div>
            </div>
        </>
    )
}

export default User
