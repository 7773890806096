import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

.ant-select-dropdown {
    background: white;
    min-width: 0px !important;
    // max-width: 70px !important;
    // width: 50px !important;
    border-radius: 3px;
    // top: 45px !important;
    padding: 0;
}
.ant-select-dropdown .ant-select-item {
    border-radius: 0;
}

.ant-collapse-header {
    padding: 12px 16px 12px 0 !important;
}

.ant-select-item-option-active {
    background-color: #7BB6E1 !important;
    color: white !important;
}

.ant-collapse-item-active>div:nth-child(1)>span {
    color: #7BB6E1 !important;
}

.ant-collapse-item-active>div:nth-child(1)>div:nth-child(1) span{
    background: #7BB6E1 !important;
    transform: rotate(180deg) !important;
    color: white !important;
    border-color: #7BB6E1 !important; 
}

.ant-collapse-content {
    background: rgba(38, 52, 137, 0.02) !important;
    color: #1A2560 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    
}


.ant-collapse-item-active>div:nth-child(2)>div>div{
    background: rgba(38, 52, 137, 0.02) !important;
    color: #1A2560 !important;
    border-color: #7BB6E1 !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 20px 32px;
}

ul .active {
    border-bottom: 4px solid #263489;
}

// .slick-slide {
//     width: 97% !important;
//     margin: 0 .4%;
//     cursor: pointer !important;
// }

.ant-collapse {
    background-color: transparent;
}
.slick-arrow:before {
    display: none !important;
}

.slick-prev span {
    margin-left: 4px; 
}
.slick-next span {
    margin-left: 8px; 
}

.ant-checkbox-inner {
    border-color: #7BB6E1 !important;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #7BB6E1 !important;
    border:1px solid #7BB6E1 !important;
    border-radius: 2px !important;
    border-color: #7BB6E1 !important;
}

.ant-select-dropdown .ant-select-item, .ant-select-selection-item {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 25px;
    color: #263489 !important;
}

.ant-select.ant-select-in-form-item {
    width: 714px;
}

.ant-btn:focus-visible{
    outline: none !important;
}
.lbtn {
     font-size: 16px!important;
	 margin-bottom:50px!important;
}
.lbtn a{
     color:white!important;
}
.lbtn a:hover{
     color:white!important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon, .ant-steps .ant-steps-item-icon > .ant-steps-icon{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: rgba(38, 52, 137, 0.3) !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon, .ant-steps .ant-steps-item-icon  {
    background: #FFFFFF;
    border: 1px solid rgba(38, 52, 137, 0.3);
    height: 48px !important;
    width: 48px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.ant-steps .ant-steps-item-custom >.ant-steps-item-container>.ant-steps-item-icon >.ant-steps-icon{
    height: 48px !important;
    width: 48px !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon, .ant-steps .ant-steps-item-icon>.ant-steps-icon {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    // font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: rgba(38, 52, 137, 0.3);
}

.ant-steps .ant-steps-item-tail {
    top: 19px;
}
.ant-steps .ant-steps-item-tail:after {
    background-color: rgba(38, 52, 137, 0.3) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
    background: #FFFFFF !important;
    border: 2px solid #7BB6E1 !important;
}
.ant-steps-item-finish .ant-steps-item-icon span {
    font-weight: bold !important;
    font-size: 21px;
    // margin-top: 2px;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #7BB6E1 !important;
}
.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
    margin-inline-start: 72px;
    padding: 4px 15px;
}

.ant-steps-item-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 25px !important;
    text-align: center;
    color: #1A2560 !important;
}

.ant-steps-item-description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 25px;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon{
    background: #fff;
    border: 1px solid rgba(38, 52, 137, 0.3);
    color: white !important;
}

@media only screen and (max-width: 575px) {
    .ant-select-dropdown {
        background: white;
        border-radius: 8px;
        min-width: 0px !important;
        // max-width: 70px !important;
        // width: 50px !important;
        // top: 42% !important;
        top: 0px;
        left: 51%;
    }

    .slick-track {
        max-height: 200px;
    }
}

`;